import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import api from '../../../../../../services/api';
import * as config from "../../../../../../config";
import ChartCard from "../../../../../../components/CustomComposedChart";

const DerivativesAnalysisWrapper = (props) => {
    const { stockSymbol, layoutWidth, layoutHeight } = props;
    const fakeData = require('./fakeData.json')
    const [derivativesData, setDerivativesData] = useState([]);
    const [propDerivativeData,setPropDerivativeData] = useState([]);
    const { t } = useTranslation();

    const layoutColumns = 3;
    const layoutRows = 3;

    const accumulated = stockSymbol.slice(-3)
    let chartWidth = layoutWidth > config.RESPONSIVE_SCREEN.lg ? (layoutWidth - 100) / layoutColumns : (layoutWidth - 100) / 2;
    let chartHeight = layoutHeight > 570 ? (layoutHeight - 85) / layoutRows - 35 : 200;

    const fetchDerivativeData = useCallback(async () => {
        const [error, response]: any = await api.get(
            `plugins/derivative/data-days/${stockSymbol}`,
        );

        if (error) {
            console.log(error);
            return;
        }

        setDerivativesData(response.data.reverse());
    }, [stockSymbol]);

    const fetchProprietaryData = useCallback(async () => {
        const [error, response]: any = await api.get(
            `/plugins/derivative/proprietary/${stockSymbol}`
        )

        if(error) {
            return;
        }

        setPropDerivativeData(response.data.reverse());
    }, [stockSymbol]);

    useEffect(() => {
        fetchDerivativeData();
        fetchProprietaryData();
    }, [fetchDerivativeData, fetchProprietaryData]);

    const charts = [
        {
            key: 'changeAndRange',
            chartType: "composed",
            title: t('Biến động chỉ số và Độ rộng trong phiên'),
            timeType: 'day',
            YAxisUnit: '',
            tooltipOutsideBoxPosition: 'bottom',
            chartStructure: {
                area: [],
                bar: [],
                line: [
                    { dataKey: 'valueChange', name: t(`(+/-) ${stockSymbol.toUpperCase()}`), yAxisType: "left", unit: '' },
                    { dataKey: 'amplitude', name: t('Độ rộng'), yAxisType: "left", unit: '' },
                    { dataKey: 'vn30ValueChange', name: t('(+/-) VN30'), yAxisType: "left", unit: '' },
                    { dataKey: 'basis', name: t('Basis'), yAxisType: "left", unit: '' },
                ]
            },
        },
        {
            key: 'volumeAndOI',
            chartType: "composed",
            title: t('Khối lượng giao dịch và khối lượng mở'),
            timeType: 'day',
            YAxisUnit: '',
            tooltipOutsideBoxPosition: 'bottom',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'totalVolume', name: t('KLGD'), yAxisType: "left", unit: '' },
                ],
                line: [
                    { dataKey: 'openInterest', name: t('OI'), yAxisType: "left", unit: '' },
                ],
            }
        },
        {
            key: 'oi',
            chartType: "composed",
            title: t('Biến động khối lượng mở (OI)'),
            timeType: 'day',
            YAxisUnit: '',
            barColorByValue: true,
            tooltipOutsideBoxPosition: 'bottom',
            chartStructure: {
                area: [],
                bar: [{ dataKey: 'openInterestValueChange', name: t('(+/-) OI'), yAxisType: "left", unit: '' }],
                line: [{ dataKey: 'openInterest', name: t('OI'), yAxisType: "left", unit: '' }],
            },
        },
        {
            key: 'foreignTrading',
            chartType: "composed",
            title: t('Giao dịch nhà đầu tư nước ngoài'),
            timeType: 'day',
            YAxisUnit: t('CP'),
            barColorByValue: true,
            tooltipOutsideBoxPosition: 'bottom',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'foreignNetVolume', name: t('Khối lượng Ròng'), yAxisType: "left", unit: t('CP') },
                ],
                line: [
                    { dataKey: 'foreignAccumulatedVolume', name: t('Khối lượng Luỹ kế'), yAxisType: "left", unit: t('CP') },
                    { dataKey: 'foreignBuyVolume', name: t('Khối lượng Mua'), yAxisType: "left", unit: t('CP') },
                    { dataKey: 'foreignSellVolume', name: t('Khối lượng Bán'), yAxisType: "left", unit: t('CP') },
                ],
            },
        },
        {
            key: `${stockSymbol}/vn30`,
            chartType: "composed",
            title: t('Tương quan HĐTL và VN30'),
            timeType: 'day',
            YAxisUnit: '',
            secondYAxis: true,
            secondYAxisUnit: '',
            barColorByValue: true,
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'basis',  name: t('Basis'), yAxisType: "right", unit: '' },
                ],
                line: [
                    { dataKey: 'close', name: t(`Đóng ${stockSymbol.toUpperCase()}`), yAxisType: "left", unit: '' },
                    { dataKey: 'vn30Value', name: t('VN30'), yAxisType: "left", unit: '' },
                ],
            },
        },
        {
            key: 'accumulated',
            chartType: "composed",
            title: t('Biến động và luỹ kế'),
            timeType: 'day',
            YAxisUnit: '',
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [],
                line: [
                    { dataKey: 'valueChange', name: t(`(+/-) ${stockSymbol.toUpperCase()}`), yAxisType: "left", unit: '' },
                    { dataKey: 'valueChangeAccumulated', name: t(`Luỹ kế (+/-) ${accumulated.toUpperCase()}`), yAxisType: "left", unit: '' },
                    { dataKey: 'vn30ValueChange', name: t('(+/-) VN30'), yAxisType: "left", unit: '' },
                    { dataKey: 'vn30ValueChangeAccumulated', name: t('Luỹ kế (+/-) VN30'), yAxisType: "left", unit: '' },
                ],
            },
        },
    ];

    const propCharts = [
        {
            key: 'proprietaryDerivative',
            chartType: "composed",
            title: t('Giao dịch tự doanh'),
            timeType: 'day',
            YAxisUnit: '',
            barColorByValue: true,
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'amountPropDerivativeVolume', name: t('Khối lượng ròng'), yAxisType: "left", unit: '' },
                ],
                line: [
                    { dataKey: 'propAccumulatedVolume', name: t('Khối lượng lũy kế'), yAxisType: "left", unit: '' },
                    { dataKey: 'propDerivativeBuyVolume', name: t('Khối lượng mua'), yAxisType: "left", unit: '' },
                    { dataKey: 'propDerivativeSellVolume', name: t('Khối lượng bán'), yAxisType: "left", unit: '' },
                ]
            },
        },
    ];

    const propForeChart = [
        {
            key: 'propForeChange',
            chartType: "composed",
            title: `${t('Chênh lệch GD tự doanh & nước ngoài')}`,
            timeType: 'day',
            YAxisUnit: '',
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [],
                line: [
                    { dataKey: 'propForeAmount', name: t('NN-TD Ròng'), yAxisType: "left", unit: '' },
                    { dataKey: 'propForeAccumulated', name: t('NN-TD Lũy kế'), yAxisType: "left", unit: '' },
                ]
            },
        },
        {
            key: 'propForeAccumulated',
            chartType: "composed",
            title: `${t('Tương quan lũy kế GD tự doanh & nước ngoài')}`,
            timeType: 'day',
            YAxisUnit: '',
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'propForeAccumulated', name: t('NN Lũy kế'), yAxisType: "left", unit: '' },
                ],
                line: [
                    { dataKey: 'foreAccumulated', name: t('NN Lũy kế'), yAxisType: "left", unit: '' },
                    { dataKey: 'propAccumulated', name: t('TD Lũy kế'), yAxisType: "left", unit: '' },
                ]
            },
        },
    ]
    return <div className="overflow-y-auto px-1" style={{ height: layoutHeight }}>
        <Row gutter={[4, 4]}>
            {charts.map(item => {
                return <Col span={24/layoutColumns} key={item.key}>
                    <ChartCard
                        key={item.key}
                        title={item.title}
                        width={chartWidth}
                        height={chartHeight}
                        data={derivativesData}
                        timeType={item.timeType}
                        chartType={item.chartType}
                        chartStructure={item.chartStructure}
                        YAxisUnit={item.YAxisUnit}
                        secondYAxis={item.secondYAxis}
                        secondYAxisUnit={item.secondYAxisUnit}
                        barColorByValue={item.barColorByValue}
                        tooltipOutsideBoxPosition={item?.tooltipOutsideBoxPosition}
                    />
                </Col>
            })}
            {propCharts.map(item => {
                return <Col span={24/layoutColumns} key={item.key}>
                    <ChartCard
                        key={item.key}
                        title={item.title}
                        width={chartWidth}
                        height={chartHeight}
                        data={propDerivativeData}
                        timeType={item.timeType}
                        chartType={item.chartType}
                        chartStructure={item.chartStructure}
                        YAxisUnit={item.YAxisUnit}
                        barColorByValue={item.barColorByValue}
                        tooltipOutsideBoxPosition={item?.tooltipOutsideBoxPosition}
                    />
                </Col>
            })}
            {propForeChart.map(item => {
                return <Col span={24/layoutColumns} key={item.key}>
                    <ChartCard
                        key={item.key}
                        title={item.title}
                        width={chartWidth}
                        height={chartHeight}
                        data={fakeData[stockSymbol.toUpperCase()].reverse().slice(-20)}
                        timeType={item.timeType}
                        chartType={item.chartType}
                        chartStructure={item.chartStructure}
                        YAxisUnit={item.YAxisUnit}
                        tooltipOutsideBoxPosition={item?.tooltipOutsideBoxPosition}
                    />
                </Col>
            })}
        </Row>
    </div>
}

export default DerivativesAnalysisWrapper;